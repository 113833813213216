<template>
    <div class="dizme_tm_all_wrap" data-magic-cursor="show">
    
    <!--   <MobileHeaderVue />
      <HeaderVue />
    
PORTFOLIO -->
    <ShopComponent />
    <!-- /PORTFOLIO -->
     
     
  
      <!-- COPYRIGHT -->
      <CopyrightComponent />
      <!-- /COPYRIGHT -->
  
      <ScrollTopVue />
    </div>
  </template>
  
  <script>
 // import HeaderVue from "@/components/layout/Header.vue";
 // import MobileHeaderVue from "@/components/layout/MobileHeader.vue";
  import ScrollTopVue from "@/components/layout/ScrollTop.vue";
  import { activeSkillProgress } from "@/utilits";
  //import AboutComponent from "../AboutComponent.vue";
  import ShopComponent from "../ShopComponent.vue";
  import CopyrightComponent from "../CopyrightComponent.vue";

  
  export default {
    name: "ShopV",
    components: {
     
      ShopComponent,
    
      CopyrightComponent,
    
    //  HeaderVue,
     // MobileHeaderVue,
      ScrollTopVue,
    },
    mounted() {
      window.addEventListener("scroll", activeSkillProgress);
    },
  };
  </script>
  