import { createRouter, createWebHistory } from "vue-router";
import IndexDarkView from "../views/IndexDarkView.vue";
import IndexView from "../views/IndexView.vue";
import IntroView from "../views/IntroView.vue";
import AboutV from "../components/viewss/AboutV.vue";
import ContactV from "../components/viewss/ContactV.vue";
import BlogV from "../components/viewss/BlogV.vue";
import ShopV from "../components/viewss/ShopV.vue";
import GalleryV from "../components/viewss/GalleryV.vue";
import GalleryDV from "../components/viewss/GalleryDV.vue";
import PortfolioV from "../components/viewss/PortfolioV.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    component: IndexView,
  },
  {
    path: "/dark",
    name: "IndexDarkView",
    component: IndexDarkView,
  },
  {
    path: "/intro",
    name: "IntroView",
    component: IntroView,
  },
  {
    path: "/about",
    name: "About",
    component: AboutV,
  },
  {
    path: "/blog",
    name: "Blog",
    component: BlogV,
  },
  {
    path: "/shop",
    name: "Shop",
    component: ShopV,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: GalleryV,
  },
  {
    path: "/detail",
    name: "Detail",
    component: GalleryDV,
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: PortfolioV,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactV,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
