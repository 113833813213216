<template>
  <div class="dizme_tm_section" id="service">
    <div class="dizme_tm_services">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>Services</span>
          <h3>What We Do </h3>
          <p>
           
          </p>
        </div>
        <div class="service_list">
          <ul>
            <li
              class="wow fadeInLeft"
              data-wow-duration="1s"
              @click.prevent="active = 1"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/svg/service/anchor.svg" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/1.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>TEXTILE DESIGN & SURFACE PATTERN</h3>
                  
                </div>
                <div class="text">
                  <p>
                    All of our textile and surface pattern designs are original one-off designs. They are all available in repeat and as 
                    Illustrator ai or Photoshop psd files which means they are ready to use and easy to edit.
                  </p>
                </div>
                <a class="dizme_tm_full_link" href="#"></a>
                <img
                  class="popup_service_image"
                  src="/img/service/1.jpg"
                  alt=""
                />
                <div class="service_hidden_details">
                  <div class="service_popup_informations">
                    <div class="descriptions">
                      <p>
                        TEXTILE DESIGN & SURFACE PATTERN
                      </p>
                      <p>
                        All of our textile and surface pattern designs are original one-off designs. They are all available in repeat and as 
                    Illustrator ai or Photoshop psd files which means they are ready to use and easy to edit.
                      </p>
                      <p>
                       
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li
              class="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              @click.prevent="active = 2"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/svg/service/physics.svg" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/2.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>CUSTOM-MADE FOR YOU</h3>
                  
                </div>
                <div class="text">
                  <p>
                    Are you looking for something a little more specific or want to try out an idea? 
                    A big part of the work that we do here at Nev+Art is commissioned by you.
                  </p>
                </div>
                <a class="dizme_tm_full_link" href="#"></a>
                <img
                  class="popup_service_image"
                  src="/img/service/2.jpg"
                  alt=""
                />
                <div class="service_hidden_details">
                  <div class="service_popup_informations">
                    <div class="descriptions">
                      <p>
                       asdsadize your revenue to help grow
                        your business and achieve your goals.
                      </p>
                      <p>
                        In today’s digital world, your website is the first
                        interaction consumers have with your business. That's
                        why almost 95 percent of a user’s first impression
                        relates to web design. It’s also why web design services
                        can have an immense impact on your company’s bottom
                        line.
                      </p>
                      <p>
                        That’s why more companies are not only reevaluating
                        their website’s design but also partnering with Kura,
                        the web design agency that’s driven more than $2.4
                        billion in revenue for its clients. With over 50 web
                        design awards under our belt, we're confident we can
                        design a custom website that drives sales for your
                        unique business.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
         
          </ul>
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="/img/brushes/service/5.png" alt="" />
      </div>
      <div class="brush_2 wow zoomIn" data-wow-duration="1s">
        <img src="/img/brushes/service/6.png" alt="" />
      </div>
    </div>
  </div>
  <div :class="active == 1 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/service/1.jpg"
            style="background-image: url('img/service/1.jpg')"
          ></div>
        </div>
        <div class="main_title">
          <h3> TEXTILE DESIGN & SURFACE PATTERN</h3>
         
        </div>
        <div class="descriptions">
          <p>
            All of our textile and surface pattern designs are original one-off designs. They are all available in repeat and as 
                    Illustrator ai or Photoshop psd files which means they are ready to use and easy to edit. 
          </p>
          <p>
            All of our carpet /rug pattern designs are original one-off designs. They are all available in BMP files which means they are ready to use and easy to edit. 
          </p>
          <p>
            If you would like to look at our collection online just drop us a request for a login using the button at the bottom of the page and 
            we'll get you set up or let us know where you are and we'll bring the collection to you.
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 2 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/service/2.jpg"
            style="background-image: url('img/service/2.jpg')"
          ></div>
        </div>
        <div class="main_title">
          <h3>CUSTOM-MADE FOR YOU</h3>
          
        </div>
        <div class="descriptions">
          <p>
            Our skilled textile artists design custom-made commissioned artwork to meet your unique needs.
             When you choose us, you can expect:
          </p>
          <p>
            Consult with our creative director to make sure we are all on the same page and we can match you with one of our amazing designers.
Two drafts with the option to request changes or amendments.
One final artwork file in Psd format with RGB 1998 colour profile, 300 Dpi, designed in full repeat and element and/or colour separation as per your request.
Prices start at $850 + GST.
          </p>
          <p>
          
          </p>
        </div>
      </div>
    </ModalBox>
  </div>


</template>

<script>
import ModalBox from "./popup/ModalBox.vue";
export default {
  name: "ServiceCompoent",
  data() {
    return {
      active: 0,
    };
  },
  mounted() {
    let VanillaTilt = require("vanilla-tilt");
    VanillaTilt.init(document.querySelectorAll(".tilt-effect"), {
      maxTilt: 6,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      speed: 500,
      transition: true,
    });
  },
  methods: {
    close() {
      this.active = 0;
    },
  },
  components: { ModalBox },
  props: {
    dark: { type: Boolean },
  },
};
</script>
