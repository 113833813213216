<template>
  <div class="dizme_tm_all_wrap" data-magic-cursor="show">
   <!--  <MobileHeaderVue />
    <HeaderVue /> -->
    <HomeComponent />
   

    <!-- PROCESS -->
    <ProcessComponent />
    <!-- /PROCESS -->

   

    <!-- PORTFOLIO -->
    <PortfolioComponent />
    <!-- /PORTFOLIO -->



    <!-- SERVICES -->
    <ServiceCompoent />
    <!-- /SERVICES -->

    <!-- TESTIMONIALS -->
    
    <!-- /TESTIMONIALS -->

    <!-- PARTNERS -->
    
    <!-- /PARTNERS -->

    <!-- NEWS -->
    <NewsComponent />
    <!-- /NEWS -->


    <!-- CONTACT -->
    <ContactComponent />
    <!-- /CONTACT -->

    <!-- COPYRIGHT -->
    <CopyrightComponent />
    <!-- /COPYRIGHT -->

    <ScrollTopVue />
  </div>
</template>

<script>
//import HeaderVue from "@/components/layout/Header.vue";
//import MobileHeaderVue from "@/components/layout/MobileHeader.vue";
import ScrollTopVue from "@/components/layout/ScrollTop.vue";
import { activeSkillProgress } from "@/utilits";
//import AboutComponent from "../components/AboutComponent.vue";
import ContactComponent from "../components/ContactComponent.vue";
import CopyrightComponent from "../components/CopyrightComponent.vue";
import HomeComponent from "../components/HomeComponent.vue";
import NewsComponent from "../components/NewsComponent.vue";
//import PartnersComponent from "../components/PartnersComponent.vue";
import PortfolioComponent from "../components/PortfolioComponent.vue";
import ProcessComponent from "../components/ProcessComponent.vue";
import ServiceCompoent from "../components/ServiceCompoent.vue";
//import SkillComponent from "../components/SkillComponent.vue";
//import SubscribeComponent from "../components/SubscribeComponent.vue";
//import TestimonialsComponent from "../components/TestimonialsComponent.vue";
import {
  dataImage,
  portfolioHover,
  scroll_,
  stickyNav,
  wowJsAnimation,
} from "@/utilits";
export default {
  name: "IndexView",
  components: {
    HomeComponent,
    ProcessComponent,
   // AboutComponent,
    PortfolioComponent,
  //  SkillComponent,
    ServiceCompoent,
   // TestimonialsComponent,
   // PartnersComponent,
    NewsComponent,
    //SubscribeComponent,
    CopyrightComponent,
    ContactComponent,
    //HeaderVue,
    //MobileHeaderVue,
    ScrollTopVue,
  },

  mounted() {
    window.addEventListener("scroll", activeSkillProgress);

    wowJsAnimation();
    window.addEventListener("scroll", stickyNav);
    window.addEventListener("scroll", scroll_);
    setTimeout(() => {
      dataImage();
      portfolioHover();
    }, 100);
  },
};
</script>
