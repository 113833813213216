<template>
  <div class="dizme_tm_header">
    <div class="container">
      <div class="inner">
        <div class="logo">
          <router-link to="/">
            <img :src="`img/logo/logo.png`" alt=""
          /></router-link>
        </div>
        <div class="menu">
          <ul class="anchor_nav">
            <li class="current"><router-link to="/">Home</router-link></li>
            <li><router-link to="/about">About</router-link></li>
            <li><router-link to="/portfolio">Portfolio</router-link></li>
            
            <li><router-link to="/blog">Blog</router-link></li>
            <li><router-link to="/contact">Contact</router-link></li>
            <li class="download_cv">
              <a href="https://app.nevartdesign.com"> <span>Shop</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderVue",
  props: {
    dark: { type: Boolean },
  },
};
</script>
