
import { defineStore } from "pinia";
import { useStorage } from '@vueuse/core'

export const useUserStore = defineStore("user", {
  state: () => ({
    user: useStorage('user',null),
    isAuth:useStorage('isAuth',false),
  }),

  actions: {
    async fetchUser() {
      const res = await fetch("https://localhost:3000/user");

      const user = await res.json();
      this.user = user;
    },
    async logout() {
     
      this.isAuth = false;
      this.user = null;
    },
   // async signUp(email, password) {
      //const res = await fetch("https://localhost:3000/register", {
      //  method: "POST",
        //headers: {
       //   "Content-Type": "application/json",
       // },
      //  body: JSON.stringify({ email, password }),
    //  });
     // const user = await res.json()
     // this.user = user;
    
        
  //  },
    async signIn(email, password) {
      if(email == "ahmet" && password == "vural") {
        this.isAuth = true;
        this.user = { name: email };
          } else {
              console.log("The username and / or password is incorrect");
              this.isAuth = false;
              
          }
    },
  },
});