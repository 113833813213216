<template>
  <div class="dizme_tm_section" id="about">
    <div class="dizme_tm_about">
      <div class="container">
        <div class="wrapper">
          <div class="left">
            <div class="image1">
              <img :src="`/img/about/ab.jpeg`" alt="" />
           
            </div>
          </div>  
          <div class="right">
            <div class="title wow fadeInUp" data-wow-duration="1s">
              <span>Welcome to </span>
              <h3>NeV+art Design Studio</h3>
            </div>
            <div class="text wow fadeInUp" data-wow-duration="1s">
              <p>
                Hi, we are Ahmet and Naime. 
                Naime brings nearly 20 years of expertise in the carpet and textile industry,
                 while Ahmet boasts over two decades of experience in graphic design and software development.
                  In the middle of 2023, we decided to turn our passion into a profession and founded Ateksi and Nevart Design Studio.
                  Now, we are delighted to be doing what we love, spending our days creating
                   innovative patterns and designs. We invite you to get to know us better as 
                   we embark on this exciting journey together at Ateksi and Nevart
                 <br />
              </p>
             
                       
                  </div>
           
          </div>
          
        </div>
        <div class="wrapper"> 
            <div class="title wow fadeInUp" data-wow-duration="1s">
              <br />
              <h3 >Our vision about Nev+Art </h3>
               <p>
                At NeV+art Design Studio, we pride ourselves on being 
                at the forefront of carpet and textile design, harnessing approximately 20 
                years of industry expertise to create exceptional designs. Founded in the heart 
                of Gaziantep, a city renowned as a capital of carpet and textile production, our studio embodies
                 a blend of tradition and modernity, essential in today’s dynamic market
                 <br /> <br />
              </p><p></p>
            <h4>Our Expertise</h4>  
              
              <p>
                Our team is not just skilled; it's a blend of experienced professionals specializing in carpet and
                 textile design. This specialization ensures that every piece we create is not just a product but a
                  testament to the art of textile design. Our designers combine their deep understanding ,
                of traditional methods with a keen eye for contemporary trends, ensuring that each design is both timeless and trendy.
              </p> <br />
              <h4><span>Innovation at Its Core </span></h4>
              <p>
                Innovation is at the heart of what we do at NeV+art Design Studio. We are continually evolving, 
                staying abreast of industry trends and advancements. This dedication to innovation means our clients 
                always receive designs that are not just in 
                vogue but also ahead of their time. We believe in creating trends, not just following them.
              </p> <br />
              <h4><span>Custom Orders for Your Unique Vision </span></h4>
              <p>
                At NeV+art, we understand that your space is as unique as you are. 
                That's why we offer personalized design services, allowing you to bring your vision to life.
                 Whether it's a custom carpet for a specific room or a bespoke 
                textile design, our team is dedicated to turning your ideas into exquisite, one-of-a-kind creations.
              </p> <br />
              <h4><span>Our Promise </span></h4>
              <p>
                To our clients, we promise a journey through the enchanting world of textile design, where each pattern tells a story and every color
                 ignites emotion. 
                We are committed to providing you with designs that are not only visually stunning but also of superior quality and craftsmanship.
              </p>
              <p>Join us at NeV+art Design Studio, where every thread weaves a story of elegance, innovation, and unmatched quality. Your unique vision deserves a custom creation.</p>
              <br /> </div>
           
          
          </div>
          <div class="dizme_tm_button wow fadeInUp" data-wow-duration="1s">
              <router-link to="/contact"><span>Contact</span></router-link>
            </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="/img/brushes/about/1.png" alt="" />
      </div>
      <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
        <img src="/img/brushes/about/2.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
//import VueJsCounter from "vue-js-counter";
export default {
  name: "AboutComponent",
  components: { 
   // VueJsCounter
   },
  props: {
    dark: { type: Boolean },
  },
};
</script>
<style scoped>
.image1{
  width: 300px;

}
</style>