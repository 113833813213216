<template>
    <div class="dizme_tm_all_wrap" data-magic-cursor="show">
         <!-- <MobileHeaderVue />
      <HeaderVue />
    

 PORTFOLIO -->
    <PortfolioComponent />
    <!-- /PORTFOLIO -->
     
     
  
      <!-- COPYRIGHT -->
      <CopyrightComponent />
      <!-- /COPYRIGHT -->
  
      <ScrollTopVue />
    </div>
  </template>
  
  <script>
  //import HeaderVue from "@/components/layout/Header.vue";
  //import MobileHeaderVue from "@/components/layout/MobileHeader.vue";
  import ScrollTopVue from "@/components/layout/ScrollTop.vue";
  import { activeSkillProgress } from "@/utilits";
  //import AboutComponent from "../AboutComponent.vue";
  import PortfolioComponent from "../PortfolioComponent.vue";
  import CopyrightComponent from "../CopyrightComponent.vue";
  import {
  dataImage,
  portfolioHover,
  scroll_,
  stickyNav,
 // wowJsAnimation,
} from "@/utilits";
  
  export default {
    name: "PortfolioV",
    components: {
     
      PortfolioComponent,
    
      CopyrightComponent,
    
    //  HeaderVue,
    //  MobileHeaderVue,
      ScrollTopVue,
    },
    mounted() {
      window.addEventListener("scroll", activeSkillProgress);
    //  wowJsAnimation();
    window.addEventListener("scroll", stickyNav);
    window.addEventListener("scroll", scroll_);
    setTimeout(() => {
      dataImage();
      portfolioHover();
    }, 100);
    },
  };
  </script>
  