<template>
  <div class="dizme_tm_section" id="portfolio">
    <div class="dizme_tm_portfolio">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>Shop</span>
          <h3>Access our pattern/design shop</h3>
          <div id="login">
          
    <label>Email</label>
    <input type="text" v-model="email" />
    <label>Password</label>
    <input type="password" v-model="password" />
    
  
        <button type="button" v-on:click="login()">Login</button>
    </div>
          <p>
            If you don't have a password, please contact us to gain access to our latest work. </p>
        </div>
        <div class="portfolio_filter">
      
        </div>
        <div class="dizme_tm_portfolio_titles"></div>
       
      </div>
      <div class="brush_1 wow zoomIn" data-wow-duration="1s">
        <img src="/img/brushes/portfolio/1.png" alt="" />
      </div>
      <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
        <img src="/img/brushes/portfolio/2.png" alt="" />
      </div>
    </div>
  </div>

</template>

<script>
//import MagnificPopUpVue from "./popup/MagnificPopUp.vue";
//import ModalBox from "./popup/ModalBox.vue";
import { useUserStore } from "../store/user";
export default {
  name: "ShopComponent",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      activeNav: "1", // props
      name: "",
      src: "",
      modal: false,
      // Content
      active: 0,
      email: "",
      password: "",
    };
  },
  mounted() {

  },
  methods: {
    onClick(name, src) {
      this.name = name;
      this.src = src;
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
      this.name = "";
      this.src = "";
    },
    close() {
      this.active = 0;
    },
    async login() {
      if(this.email != "" && this.password != "") {
        console.log(this.userStore.isAuth)
      await this.userStore.signIn(this.email, this.password);
      console.log(this.userStore.isAuth)
      if(this.userStore.isAuth) {
        console.log(this.userStore.isAuth)
                this.$router.replace({ name: "Gallery" });
                console.log(this.userStore.isAuth)
            }
    
    }
    },
  },
  components: { },
};
</script>
<style scoped>
#login .form-inputs {
    padding-bottom: 10px;
}

#login .form-inputs label {
    padding-right: 10px;
}

</style>
